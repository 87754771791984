import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

export default function ReCaptchaWebView() {
    
  const handleRecaptchaChange = async (token) => {
    if (token) {
      //enviar token a react-native por webview
      window.postMessage(JSON.stringify({ token: token }));
    }
  };

  return (
    <ReCAPTCHA
      sitekey={process.env.REACT_APP_RECAPTCHA_WEBSITE_KEY}
      onChange={handleRecaptchaChange}
    />
  );
}
