import * as React from "react";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import CircularProgress from "@mui/material/CircularProgress";
//import { CustomButton } from "../../../../../../Components/CustomMuiComponents";
import {
  DoDisturbOn,
  PauseCircle,
  Restore,
  DisplaySettings,
} from "@mui/icons-material";
import { CompetenciaContext } from "../../Context/CompetenciaContext";
import SelectWinners from "./SelectWinners";
import EliminarCompetencias from "./DeleteMany";
import { MainContext } from "../../../../../../Context/MainCtx";
import { UpdateStatusMany } from "../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { STATUS_COMP } from "../../../../../../Config/constantes";
import SetNoSePresento from "./setNP";
import { Tooltip } from "@mui/material";
import { CustomButton } from "../../../../../../Components/CustomMuiComponents";

export default function ActionSelectMenu() {
  const { snackMessage, tokenSesion } = React.useContext(MainContext);
  const {
    compQuery,
    itemsSelected,
    setItemsSelected,
    setIndeterminated,
  } = React.useContext(CompetenciaContext);
  const [updateStatusMany] = useMutation(UpdateStatusMany);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(null);
  const items = itemsSelected.length;

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSelected(null);
  };

  const handleAction = async () => {
    try {
      if (!itemsSelected.length) return;
      if (!selected) return;
      const idsComp = itemsSelected.map((res) => res._id);
      setLoading(true);
      const res = await updateStatusMany({
        variables: {
          idsComp,
          status: STATUS_COMP[selected],
        },
        context: {
          headers: {
            Authorization: `Bearer ${tokenSesion}`,
          },
        },
      });
      snackMessage({
        message: res.data.updateStatusMany.message,
        variant: "success",
      });
      setLoading(false);
      compQuery.refetch();
      setItemsSelected([]);
      setIndeterminated(false);
      handleClose();
    } catch (err) {
      setLoading(false);
      console.log(err.networkError?.result);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  const getTextButton = () => {
    switch (selected) {
      case "activo":
        return "Reanudar";
      case "pendiente":
        return "Pausar";
      case "cerrado":
        return "Terminar";
      default:
        return "Confirmar";
    }
  };

  return (
    <div>
      <Tooltip arrow title="Acciones en Competencias" placement="top">
        {/* <IconButton onClick={handleClickOpen} disabled={!items}>
          <Campaign />
        </IconButton> */}
        <CustomButton
          variant="text"
          disableElevation
          size="small"
          onClick={handleClickOpen}
          startIcon={<DisplaySettings />}
          disabled={!items}
          color="inherit"
        >
          Control
        </CustomButton>
      </Tooltip>

      <Dialog
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { minWidth: 250 },
        }}
      >
        <List dense>
          <ListItem
            disableGutters
            disablePadding
            onClick={() => setSelected("activo")}
          >
            <ListItemButton selected={selected === "activo"}>
              <ListItemIcon>
                <Restore fontSize="large" />
              </ListItemIcon>
              <ListItemText primary="Reanudar" />
            </ListItemButton>
          </ListItem>
          <ListItem
            disableGutters
            disablePadding
            onClick={() => setSelected("pendiente")}
          >
            <ListItemButton selected={selected === "pendiente"}>
              <ListItemIcon>
                <PauseCircle fontSize="large" />
              </ListItemIcon>
              <ListItemText primary="Pausar" />
            </ListItemButton>
          </ListItem>
          <ListItem
            disableGutters
            disablePadding
            onClick={() => setSelected("cerrado")}
          >
            <ListItemButton selected={selected === "cerrado"}>
              <ListItemIcon>
                <DoDisturbOn fontSize="large" />
              </ListItemIcon>
              <ListItemText primary="Cerrar" />
            </ListItemButton>
          </ListItem>
          <SelectWinners handleCloseMenu={handleClose} />
          <SetNoSePresento handleCloseMenu={handleClose} />
          <EliminarCompetencias handleCloseMenu={handleClose} />
        </List>
        <DialogActions>
          <Button
            sx={{ textTransform: "none" }}
            color="inherit"
            size="small"
            onClick={handleClose}
            variant="contained"
            fullWidth
            disableElevation
          >
            Cancelar
          </Button>
          <Button
            sx={{ textTransform: "none" }}
            color="info"
            size="small"
            onClick={handleAction}
            variant="contained"
            fullWidth
            disableElevation
            disabled={loading || !selected}
            startIcon={
              loading ? <CircularProgress color="inherit" size={20} /> : null
            }
          >
            {getTextButton()}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
