import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { Alert, Box } from "@mui/material";
import { formatoMexico, getMessageError } from "../../Config/reuserFunctions";
import { TORO } from "../../Config/constantes";
import LazyImage from "../../Components/LazyImage";
import { CheckCircle } from "@mui/icons-material";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { gql, useMutation } from "@apollo/client";

import {
  CreateMovimientoDeposito,
  CreateMovimientoError,
} from "../../Views/Home/Tienda/mutations";

import toro100 from "../../assets/images/store_100.png";
import toro200 from "../../assets/images/store_200.png";
import toro500 from "../../assets/images/store_500.png";
import toro1000 from "../../assets/images/store_1000.png";
import toro5000 from "../../assets/images/store_5000.png";
import toro10000 from "../../assets/images/store_10000.png";
import { io } from "socket.io-client";

const CreateLogError = gql`
  mutation createLogError($input: inputLogError) {
    createLogError(input: $input) {
      message
    }
  }
`;

const toros = [
  { title: "100 toros", value: 100, img: toro100, monto: 100 * TORO },
  { title: "200 toros", value: 200, img: toro200, monto: 200 * TORO },
  { title: "500 toros", value: 500, img: toro500, monto: 500 * TORO },
  { title: "1000 toros", value: 1000, img: toro1000, monto: 1000 * TORO },
  { title: "5000 toros", value: 5000, img: toro5000, monto: 5000 * TORO },
  { title: "10000 toros", value: 10000, img: toro10000, monto: 10000 * TORO },
];

const initialOptions = {
  clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
  currency: "MXN",
  intent: "capture",
};

export default function PaymentWebView() {
  const [paymentOK, setPaymentOK] = useState(false);
  const [paymentData, setPaymentData] = useState(null);

  React.useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_ENDPOINT);
    socket.on("paymentDatailUpdated", (data) => {
      if (data.isOk) {
        const toro = toros.find(({ value }) => value === data.quantity);
        setPaymentData({
          title: toro.title,
          value: toro.value,
          img: toro.img,
          monto: toro.monto,
          userId: data.userId,
          userToken: data.userToken,
        });
      }
    });
    return () => socket.disconnect();
  }, []);

  if (!paymentData) return null;

  console.log(paymentData)

  return (
    <Box>
      <Alert severity="info">
        <b>1 toro equivale a ${TORO} MXN</b>
      </Alert>
      <br />
      <Box display="flex" justifyContent="center">
        <LazyImage src={paymentData.img} alt={paymentData.title} height="150" />
      </Box>
      <Box p={2}>
        <Typography align="center" sx={{ fontSize: 16 }}>
          <b>Detalle de pago</b>
        </Typography>
        <br />
        <Box>
          <Typography align="center" sx={{ fontSize: 14 }}>
            Pago por
          </Typography>
          <Typography align="center" sx={{ fontSize: 18 }}>
            <b>{paymentData.value} Toros</b>
          </Typography>
        </Box>
        <Box>
          <Typography align="center" sx={{ fontSize: 14 }}>
            Total a pagar
          </Typography>
          <Typography align="center" sx={{ fontSize: 18 }}>
            <b>${formatoMexico(paymentData.monto)} MXN</b>
          </Typography>
        </Box>
        <br />
        {paymentOK ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CheckCircle color="success" sx={{ fontSize: 100 }} />
            <Typography align="center">
              Pago Realizado, se te abonará automaticamente tu saldo
            </Typography>
          </Box>
        ) : (
          <Box>
            <Typography align="center" sx={{ fontSize: 16 }}>
              <b>Formas de pago</b>
            </Typography>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <PayPalScriptProvider options={initialOptions}>
                <PaypalButtonsComponent
                  paymentData={paymentData}
                  setPaymentOK={setPaymentOK}
                />
              </PayPalScriptProvider>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

const PaypalButtonsComponent = ({ setPaymentOK, paymentData }) => {
  const [loading, setLoading] = React.useState(false);
  const [{ isPending }] = usePayPalScriptReducer();
  const [createLogError] = useMutation(CreateLogError);
  const [createMovimientoDeposito] = useMutation(CreateMovimientoDeposito);
  const [createMovimientoError] = useMutation(CreateMovimientoError);

  const createMovimientoDB = async (data) => {
    try {
      setLoading(true);
      const res = await createMovimientoDeposito({
        variables: {
          input: {
            usuario: paymentData.userId,
            concepto: `Compra de ${paymentData.toros} toros`,
            monto: paymentData.monto,
            orderID: data.orderID,
            payerID: data.payerID,
            paymentID: data.paymentID,
            paymentSource: data.paymentSource,
            comentarios: "",
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${paymentData.userToken}`,
          },
        },
      });
      setLoading(false);
      window.postMessage(
        JSON.stringify({
          paymentStatus: {
            isOk: true,
            message: res.data.createMovimientoDeposito.message,
          },
        })
      );
      setPaymentOK(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
      createErrorBD(error);
    }
  };

  const createOrder = (data, actions) => {
    // Lógica para crear la orden de pago
    return actions.order
      .create({
        purchase_units: [
          {
            amount: {
              value: paymentData.monto,
              currency_code: "MXN",
            },
          },
        ],
      })
      .catch((error) => {
        handleError(error, "Crear Orden");
        throw error;
      });
  };

  const handleApprove = async (data, actions) => {
    try {
      const order = await actions.order.capture();
      await createMovimientoDB(data);
      return order;
    } catch (error) {
      await handleError(error, "Aprobación");
      throw error;
    }
  };

  const handleError = async (error, from = "Unknown") => {
    try {
      window.postMessage(
        JSON.stringify({
          paymentStatus: {
            isOk: false,
            message: error?.message || "Error Desconocido",
          },
        })
      );
      setLoading(true);
      await createMovimientoError({
        variables: {
          input: {
            usuario: paymentData.userId,
            concepto: `Compra de ${paymentData.toros} toros`,
            monto: paymentData.monto,
            comentarios: `${from}: ${error?.message || "Error Desconocido"}`,
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${paymentData.userToken}`,
          },
        },
      });
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const createErrorBD = async (error) => {
    try {
      if (!error) return;
      const input = getMessageError(
        {
          ...error,
          funcion: "createMovimientoDeposito",
        },
        paymentData.userId
      );
      if (!input) return;
      window.postMessage(
        JSON.stringify({
          paymentStatus: {
            isOk: false,
            message: input.message,
          },
        })
      );
      await createLogError({
        variables: {
          input,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <PayPalButtons
      createOrder={createOrder}
      onApprove={handleApprove}
      onError={handleError}
      disabled={isPending || loading || !paymentData.userId}
    />
  );
};
