import React from "react";
import Button from "@mui/material/Button";
import { styled, alpha } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { RiFileExcel2Line } from "react-icons/ri";
import { exportToCSV } from "react-easy-export";
import { CircularProgress, ListItemIcon, ListItemText } from "@mui/material";
import { green } from "@mui/material/colors";
import { Download } from "@mui/icons-material";
import UploadXlsx from "./UploadXlsx";

export default function ExcelExportButton({
  name = "export",
  data = [],
  getQueryData = null,
  loading = false,
  size = "small",
  importModel = "",
  refetchModel = null,
  onlyUpload = false,
  factorCuota = 3,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const exportPage = () => {
    exportToCSV(data, `${name}.csv`);
    handleClose();
  };
  const exportAllData = async () => {
    const response = await getQueryData();
    if (response) exportToCSV(response, `${name}.csv`);
    handleClose();
  };

  return (
    <>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        size={size}
        color="success"
        sx={{ bgcolor: green[600] }}
        disableElevation
        onClick={handleClick}
      >
        {loading ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          <RiFileExcel2Line style={{ fontSize: 19, margin: "2px 0" }} />
        )}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {importModel ? (
          <MenuItem disableRipple>
            <UploadXlsx
              handleCloseMenu={handleClose}
              model={importModel}
              refetchModel={refetchModel}
              onlyUpload={onlyUpload}
              factorCuota={factorCuota}
            />
          </MenuItem>
        ) : null}
        <MenuItem onClick={exportPage} disableRipple>
          <ListItemIcon>
            <Download />
          </ListItemIcon>
          <ListItemText primary="Exportal actual" />
        </MenuItem>
        <MenuItem onClick={exportAllData} disableRipple>
          <ListItemIcon>
            <Download />
          </ListItemIcon>
          <ListItemText primary="Exportar todo" />
        </MenuItem>
      </StyledMenu>
    </>
  );
}

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
