import { gql } from "@apollo/client";

export const CreateCompetencia = gql`
  mutation createCompetencia($input: CompetenciaInput) {
    createCompetencia(input: $input) {
      message
    }
  }
`;
export const UpdateCompetencia = gql`
  mutation updateCompetencia($idCompetencia: ID!, $input: CompetenciaInput) {
    updateCompetencia(idCompetencia: $idCompetencia, input: $input) {
      message
    }
  }
`;
export const CancelarApuestasCompetencia = gql`
  mutation cancelarApuestasCompetencia($idCompetencia: ID!) {
    cancelarApuestasCompetencia(idCompetencia: $idCompetencia) {
      message
    }
  }
`;
export const UpdateStatusCompetencia = gql`
  mutation updateStatusCompetencia($idCompetencia: ID!, $status: String) {
    updateStatusCompetencia(idCompetencia: $idCompetencia, status: $status) {
      message
    }
  }
`;
export const DeleteCompetencia = gql`
  mutation deleteCompetencia($idCompetencia: ID!) {
    deleteCompetencia(idCompetencia: $idCompetencia) {
      message
    }
  }
`;
export const UpdateStatusMany = gql`
  mutation updateStatusMany($idsComp: [ID!], $status: String!) {
    updateStatusMany(idsComp: $idsComp, status: $status) {
      message
    }
  }
`;
export const SelectWinnersComp = gql`
  mutation selectWinnersComp($input: CompCloseInput) {
    selectWinnersComp(input: $input) {
      message
    }
  }
`;
export const DeleteManyComp = gql`
  mutation deleteManyComp($idsComp: [ID!]) {
    deleteManyComp(idsComp: $idsComp) {
      message
    }
  }
`;
export const UpdateStateNPComp = gql`
  mutation updateStateNPComp($idCompetencia: ID) {
    updateStateNPComp(idCompetencia: $idCompetencia) {
      message
    }
  }
`;
export const UpdateConfigFactor = gql`
  mutation updateConfigFactor($idsComp: [ID!], $typeFactor: Int) {
    updateConfigFactor(idsComp: $idsComp, typeFactor: $typeFactor) {
      message
    }
  }
`;
export const UpdateConfigCuota = gql`
  mutation updateConfigCuota($idsComp: [ID!], $typeCuota: Int) {
    updateConfigCuota(idsComp: $idsComp, typeCuota: $typeCuota) {
      message
    }
  }
`;